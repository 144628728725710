@import '../SharedStyles/tooltip.scss';

.productListModal-last_name {
	display: none !important;
}

@media screen and (max-width: 1024px) {
	div.productListModal-container {
		max-width: unset;
	}
	div.productListModal-header .closeModal {
		display: none;
	}
	div.productListModal-container {
		margin: 0;
	}
	div.productListModal-container-content {
		height: 100%;
	}
	div.productListModal-close {
		min-height: 75px;
		display: flex;
		
	}
	div.productListModal-header {
		padding: 15px 30px;
		h1 {
			font-size: 18px;
			margin: 0;
		}
	}
	div.productListModal-body {
		max-height: unset;
		height: 100%;
		padding: 0 15px;
		.productListModal-item {
			align-items: center;
			padding: 10px 0;
			.productListModal-item-right {
				width: 100px;
			}
		}
		.productListModal-item-image-container {
			max-width: 75px;
			max-height: 75px;

		}
	}
	div.productListModal-totalPrice {
		padding: 15px;
		h1 {
			font-size: 24px;
		}
	}
}

@media screen and (max-width: 768px) {
	form.productListModal-email-form {
		flex-wrap: wrap;
		label {
			width: 100%;
			margin: 0;
		}
		input {
			width: 100%;
			margin: 0;
			margin-top: 10px;
		}
		button {
			width: 100%;
			margin: 0;
			margin-top: 10px;
			padding: 12px 12px;

		}
	}
}

@media screen and (max-width: 400px) {

 	.productListModal-item {
		 display: block;
		.productListModal-item-right {
			width: 100px;
			
		}
		.productListModal-item-description-brand{
			display: block;
			padding-left: 5px;
			font-size: smaller;
		}

		.modalPriceNok{
			font-size: 11px;
		}

	} 

	.totalPriceNok{
		font-size: 12px !important;
	}


	div.productListModal-header {
		
		padding: 7px 30px;
		h1 {
			font-size: 14px;
			margin: 0;
		}
		button {
			font-size: 12px;
		}
	}
	div.productListModal-totalPrice h1 {
		font-size: 14px;
	}
	form.productListModal-email-form button {
		padding: 8px 12px;
	}
	div.productListModal-item .productListModal-item-description .productListModal-item-description-name {
		font-size: 14px;
		
	}

	div.productListModal-item-count  {
		button {
			margin: 0;
			padding: 0;
			
		}
	
	}

	div.productListModal-item-right div {
		
		font-size: medium;
	}

}

 .productListModal-item-count {
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		margin: 0;
	}
	button {
		background-color: transparent;
		border: none;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0 5px;
		margin-left: 0px;
		font-size: 24px;
	}
} 

.productListModal {
	font-family    : DINPro;
	position       : fixed;
	display        : flex;
	justify-content: center;
	width          : 100vw;
	z-index        : 11;
	font-family    : DINPro;
}

.productListModal-item-details{
	display       : flex;
	flex-direction: row;
	justify-content: space-around;
		
}

.productListModal-container {
	display       : flex;
	flex-direction: column;
	transform     : translateY(0);
	max-width     : 830px;
	margin        : 30px;
	width         : 100%;
}

.productListModal-item-description-container{
	display: block;
}

.productListModal-container-content {
	display         : flex;
	flex-direction  : column;
	max-height      : 100%;
	height          : fit-content;
	background-color: white;
	box-shadow      : 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}

.productListModal-header {
	display        : flex;
	justify-content: space-between;
	align-items    : center;
	max-height     : 80px;
	padding        : 30px;
	color          : #242A4A;

	div {
		display: flex;
	}

	h1 {
		font-size  : 28px;
		font-family: DINPro;
	}

	button {
		display         : flex;
		align-items     : center;
		background-color: white;
		border          : none;
		font-size       : 14px;
		margin-left     : 45px;
		font-family     : DINPro;
	}

	.closeModal {
		position        : fixed;
		border          : 1px solid black;
		z-index         : 13;
		height          : 40px;
		width           : 40px;
		display         : flex;
		align-items     : center;
		justify-content : center;
		border-radius   : 100%;
		background-color: rgba(255, 255, 255, 1);
		opacity         : 1;
		right           : -20px;
		top             : -20px;

		.closeIcon {
			transform: rotate(45deg);
			height   : 50px;
		}

		.iconFiller {
			position        : absolute;
			border-radius   : 100%;
			opacity         : 0;
			height          : 100%;
			width           : 100%;
			background-color: red;
			z-index         : 14;
		}
	}
}

.productListModal-body {
	display       : block;
	flex-direction: column;
	height        : auto;
	padding       : 0 30px;
	overflow-y    : scroll;
	max-height    : 600px;
	height        : 400px;
}

.productListModal-item {
	font-family    : DINPro;
	border-top     : 1px solid #e5e5e5;
	display        : block;
	padding        : 10px;
	justify-content: space-between;

	.productListModal-item-image-container {
		display: flex;
		align-items: center;
		width: 125px;
		height: 125px;
		flex: 1;
	}

	.productListModal-item-image {
		max-width: 100%;
		max-height: 100%;
		
	}

	.productListModal-item-description {
		display        : flex;
		flex           : 1;
		flex-direction : column;
		justify-content: center;

		.productListModal-item-description-brand {
			
			font-family: DINPro-Bold;
			color      : #272626;
			font-size  : 14px;
			margin-left: 10px;
		}

		.productListModal-item-description-name {
			color    : #242B49;
			font-size: 18px;
		}

		.productListModal-item-description-size {
			color    : #242B49;
			font-size: 14px;

		}

		p {
			font-family: DINPro;
			margin     : 0;
		}

		div {
			display       : flex;
			flex-direction: column;
		}
	}

	.productListModal-item-right {
		display        : flex;
		justify-content: space-between;
		align-items    : center;
		width          : 160px;
		height         : inherit;

		p {
			margin        : 0;
			color         : #000000;
			font-size     : 22px;
			font-family   : DINPro-Bold;
			font-size     : 22px;
			letter-spacing: 0px;
		}
	}
}

.productListModal-package-dropdown {
	border-top     : 1px solid #e5e5e5;
	display        : flex;
	justify-content: space-between;
	flex-direction : row;
	align-items    : center;
	height         : 50px;
	text-transform : uppercase;
	padding        : 10px;

	.productListModal-package-dropdown-packageIcon {
		position: relative;
		left    : -85px;

		.icon {
			height: 22px;
		}
	}

	.productListModal-package-dropdown-text {
		position      : relative;
		left          : -165px;
		color         : rgb(36, 43, 73);
		font-family   : DINPro-Medium;
		font-size     : 18px;
		letter-spacing: 0.88px;
	}

	.productListModal-package-dropdown-delIcon {
		position: relative;
		left    : 19px;
	}

	.productListModal-package-dropdown-price {
		font-family   : DINPro-Bold;
		color         : $saleRed;
		font-size     : 22px;
		letter-spacing: 0px;
	}
}

.bottomBorder {
	border-bottom: 5px solid rgb(229, 229, 229);
}

.productListModal-package-products {
	font-family: DINPro;
	overflow   : hidden;

	.productListModal-item {
		border-top     : 1px solid #e5e5e5;
		display        : flex;
		padding        : 10px;
		padding-left   : 80px;
		justify-content: space-between;

		.productListModal-item-image {
			max-width : 125px;
			max-height: 125px;
		}

		.productListModal-item-description {
			display        : flex;
			flex           : 1;
			flex-direction : column;
			justify-content: space-between;

			.productListModal-item-description-brand {
				font-family: DINPro;
				color      : #272626;
				font-size  : 14px;
			}

			.productListModal-item-description-name {
				color    : #242B49;
				font-size: 18px;
			}

			.productListModal-item-description-size {
				color    : #242B49;
				font-size: 14px;
			}

			p {
				margin: 0;
			}

			div {
				display       : flex;
				flex-direction: column;
			}
		}

		.productListModal-item-right {
			text-decoration: line-through;
			display        : flex;
			justify-content: space-between;
			align-items    : center;
			width          : 160px;
			height         : inherit;

			p {
				margin        : 0;
				color         : #000000;
				font-size     : 22px;
				font-family   : DINPro-Bold;
				font-size     : 22px;
				letter-spacing: 0px;
			}
		}
	}
}

.productListModal-totalPrice {
	font-family    : DINPro-Bold;
	display        : flex;
	justify-content: space-between;
	border-top     : 1px solid #e5e5e5;
	padding        : 30px;
	max-height     : 120px;

	h1 {
		font-size: 32px;
		color    : #242A4A;
		margin   : 0;
	}
}

.productListModal-close {
	display        : none;
	justify-content: center;
	align-items    : center;
	height         : 50px;
	text-transform : uppercase;
	border-top     : 1px solid #E5E5E5;
}

.productListModal-close:hover {
	font-weight: 700;
}

.productListModal-email {
	overflow-y: auto;
	min-height: 114px;
}

.productListModal-email-form {
	display: flex ;
	height: auto;
	align-items: center;
	background-color: white;
	border-top: 1px solid #E5E5E5;
	padding: 15px;
	flex-direction: column;

	label {
		margin: 0;
		//margin-right: 10px;
		padding-bottom: 5px;
	}

	input {
	
		// flex: 1;
		width: 100%;
		display: flex;
		padding: 8px 12px;
		margin-top: 3px;
		margin-bottom: 2px;
		border: 1px solid #CCCCCC;
	}

	button {
		border: 1px solid #242A4A;
		border-radius: 100px;
		padding: 8px 12px;
		margin-left: 20px;
		transition-duration: 0.2s;
		margin-top: 20px;
		background-color: black;
		color: white; 

		&:disabled {
			color: #CCCCCC;
			border-color: #CCCCCC;
			background-color: white;
		}
	}
}

.hide {
	display: none
}

.show {
	display: flex;
	flex-direction: column;
	width: 90%;
/* 	overflow: scroll;
	height: 100vw; */
}

.orderBtn {
	border: 1px solid #242A4A;
	border-radius: 100px;
	padding: 8px 12px;
	transition-duration: 0.2s;
	margin: 20px;
/* 	background-color: black;
	color: white; */
	
	
}

.modalPriceNok{
	font-size: 12px;
	color: grey;
	text-align: right;
}

.totalPriceNok{
	font-size: 16px;
	color: grey;
	text-align: right;
}

.showEditInvoice {
	display: flex;
	flex-direction: column;
}

.hideEditInvoice {
	display: none;
}

.checkMarkAndTitleBox {
	display: flex; 
	flex-direction: row;
	justify-content: flex-start;
	padding-top: 10px;
	padding-bottom: 10px;
}

.invoiceCeckBox {
	width: 50px !important;
}

.trash-icon-cartItem {
	background-color: transparent;
	border-width: 0;
}


.invoiceCheckBoxText{
	background-color: white !important;
	border-width: 0 !important;
	border: none !important;
	padding: 0 !important;
	margin-left: 0 !important;
	margin-top: 0 !important;
	color:black !important; 

}
